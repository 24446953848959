import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HTMLContent from "../components/HTMLContent";
import PageBanner from "../components/PageBanner";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import GatsbyImage from "gatsby-image";

export const PavingTheWayPageTemplate = (props) => {
  const { banner, olsenImage, sponsorImage, mainSection } = props;

  return (
    <>
      <PageBanner>
        <div className="width-container">
          <h1 className="page-banner-header smaller-mobile-header">
            PAVING THE WAY 2024
          </h1>
          <p>{banner.description}</p>
          <div
            style={{
              maxWidth: 400,
              background: "white",
              borderRadius: 8,
              padding: 8,
              margin: "32px auto 0px",
            }}
          >
            <PreviewCompatibleImage
              imageData={banner.image}
              gatsbyImageProps={{ fadeIn: false }}
            />
          </div>
        </div>
      </PageBanner>
      <div className="layout-container">
        <div
          style={{
            maxWidth: 1000,
            margin: "auto",
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          <div>
            <p style={{ marginBottom: 20 }}>
              Please Save The Date for our 2024 Paving The Way luncheon on April
              16!
            </p>
          </div>
          <section style={{ marginBottom: 48 }}>
            <h4>Speaker - Greg Olsen</h4>
            <div style={{ maxWidth: 260, margin: "12px auto" }}>
              <GatsbyImage fluid={olsenImage.childImageSharp.fluid} />
            </div>
            <div>
              <p style={{ margin: 0 }}>Founder of the HEARTest Yard</p>
              {divider}
              <p style={{ margin: 0 }}>Football Sportscaster</p>
              {divider}
              <p style={{ margin: 0 }}>Former Carolina Panthers Tight End</p>
            </div>
          </section>
          <section style={{ marginBottom: 48 }}>
            <h4>Sponsors</h4>
            <div style={{ maxWidth: 500, margin: "auto" }}>
              <GatsbyImage fluid={sponsorImage.childImageSharp.fluid} />
            </div>
          </section>
          <section style={{ marginBottom: 48 }}>
            <h4>Location</h4>
            <div style={{ fontSize: 18 }}>
              <div style={{ fontWeight: "bold", marginBottom: 8 }}>
                NASCAR Hall of Fame
              </div>
              <div>400 E M.L.K. Jr Blvd</div>
              <div>Charlotte, NC 28202</div>
            </div>
          </section>
          <section style={{ marginBottom: 60 }}>
            <a
              href="http://weblink.donorperfect.​com/ptw"
              className="btn primary-btn full-width-sm"
              rel="noopener noreferrer"
              style={{ minWidth: 240, fontSize: 28 }}
            >
              DONATE NOW
            </a>
          </section>
          <section style={{ paddingBottom: 40 }}>
            <h4>Need more info?</h4>
            <div style={{ fontSize: 18 }}>
              <div>Susie Crain MS CFRE, Development Director</div>
              <div>
                <a href="mailto:scrain@philipsacademync.org">
                  scrain@philipsacademync.org
                </a>
              </div>
              <div>704.365.4533</div>
            </div>
          </section>
          <section>{mainSection}</section>
        </div>
      </div>
    </>
  );
};

const divider = (
  <span
    style={{
      display: "block",
      width: 160,
      height: 3,
      background: "#0086B3",
      borderRadius: 4,
      margin: "12px auto",
    }}
  />
);

const PavingTheWayPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { banner },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Paving the Way 2024`}
      ogUrl="/paving-the-way/"
      pageDecription=""
    >
      <PavingTheWayPageTemplate
        banner={banner}
        mainSection={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.mainSection
                .markdown
            }
          />
        }
        olsenImage={data.olsen}
        sponsorImage={data.sponsor}
        nascarImage={data.nascar}
      />
    </Layout>
  );
};

export default PavingTheWayPage;

export const PavingTheWayPageQuery = graphql`
  query PavingTheWayPageQuery($id: String!) {
    olsen: file(relativePath: { eq: "ptw_olsen.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sponsor: file(relativePath: { eq: "PTWsponsors.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      fields {
        frontmatterMarkdownHTML {
          mainSection {
            markdown
          }
        }
      }
    }
  }
`;
